<template>
  <section>
    <el-dialog :style="{ overflow: 'hidden', top: topNub + 'px',width:dwidth +'%',left: dleft+'%' }" v-model="dialogShow" v-dialogDrag :visible.sync="dialogShow" :fullscreen="fullScreen" @closed="checkclose" :close-on-press-escape="false" :close-on-click-modal="false" center>
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px; position: relative; top: -3px">{{ myheadText }}</span>
        
      </template>
      <div>
        <el-tabs v-model="activeName" @tab-click="handleTabClick" style="margin-top: -25px">
          <el-tab-pane label="任务信息" name="tabTask" style="height: 80vh; overflow: auto" class="yeardiv">
            <el-collapse v-model="zhibiaoactiveNames" v-show="tabcols.length>0" @change="handleChange" style="margin-left:10px;">
              <el-collapse-item name="2">
                <template slot="title">
                  <span style="font-size: 20px; margin: 0 10px 0 30px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">指标数据概览</span>
                  <!-- <el-tooltip content="点击展开收起任务详情" placement="right-end" effect="light">
                    <i class="header-icon el-icon-info" style="font-size: 14px; margin-right: 20px"></i>
                  </el-tooltip> -->
                </template>
                <el-col :span="24">
                  
                  <vxe-table 
                    align="center"
                    show-footer
                    :footer-method="footerMethod"
                    :data="progressdata"
                    :auto-resize="true"
                    >
                    <vxe-column type="seq" width="100" align="right" title="序号"></vxe-column>
                    <vxe-column field="date" title="维护时间" ></vxe-column>
                    <vxe-column v-for="c in tabcols" v-bind:key="c" :field="c" :title="c"></vxe-column>
                  </vxe-table>
                </el-col>
              </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeNames" @change="handleChange"  style="margin-left:10px;">
              <el-collapse-item name="2">
                <template slot="title">
                  <span style="font-size: 20px; margin: 0 10px 0 30px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">工作任务详情「{{checkForm.WorkItem}}」</span>
                  <!-- <i class="el-icon-view" style="color:#999;font-size:9px;margin-right:3px">{{checkForm.ViewCount}}</i> -->
                  <!-- <el-tooltip content="点击展开收起任务详情" placement="right-end" effect="light">
                    <i class="header-icon el-icon-info" style="font-size: 14px; margin-right: 20px"></i>
                  </el-tooltip> -->
                  <!-- <span> 展开查看任务详情</span> -->
                </template>
                <el-col :span="24">
                  <el-form :model="checkForm" label-width="85px" ref="checkForm">
                    <el-form-item v-if="checkForm.ParentTaskInfo && checkForm.TaskClassify==4"  label="上级任务:" prop="ParentTaskInfo" >
                      <!-- <el-input v-model="checkForm.ParentTaskInfo" :readonly="true"></el-input> -->
                      <el-tag type="success">{{checkForm.ParentTaskInfo}}</el-tag>
                    </el-form-item>
                    
                    <el-form-item label="工作类型:" prop="CommonTaskClassifyText">
                      <!-- <el-input v-model="CommonTaskClassifyText" :readonly="true"></el-input> -->
                      <el-tag>{{CommonTaskClassifyText}}</el-tag>
                    </el-form-item>

                    <el-form-item label="工作项目:" prop="WorkItem">
                      <!-- <el-input v-model="checkForm.WorkItem" :autosize="true" :readonly="true"></el-input> -->
                      <div v-text="checkForm.WorkItem"></div>
                    </el-form-item>

                    <!-- <el-form-item label="工作描述:" prop="WorkDescription">
                      <el-input v-model="checkForm.WorkDescription" :readonly="true" type="textarea" :autosize="{ minRows: 3, maxRows: 12}"></el-input>
                    </el-form-item> -->
                    <el-form-item label="工作描述:" prop="WorkDescription">
                      <!-- <el-input v-model="checkForm.WorkDescription" :readonly="true" type="textarea" :autosize="{ minRows: 3, maxRows: 12}"></el-input> -->
                      <div style="white-space: pre-wrap" v-html="checkForm.WorkDescription"></div>
                    </el-form-item>
                    
                    <el-form-item label="责任人:" prop="PersonOfDuty">
                      <!-- <el-input v-model="checkForm.PersonOfDuty" :readonly="true"></el-input> -->
                      <div v-text="checkForm.PersonOfDuty"></div>
                    </el-form-item>

                    <el-form-item label="创建人:" prop="CreatedBy">
                      <el-tooltip :content="'创建时间：' + checkForm.CreateTime" placement="right" effect="light">
                        <!-- <el-input v-model="checkForm.CreatedBy" :readonly="true"></el-input> -->
                        <span v-text="checkForm.CreatedBy"></span>
                      </el-tooltip>
                    </el-form-item>

                    <el-form-item v-if="checkForm.AssignName && checkForm.TaskClassify != 3" label="指派人:" prop="AssignName">
                      <!-- <el-input v-model="checkForm.AssignName" :readonly="true"></el-input> -->
                      <div v-text="checkForm.AssignName"></div>
                    </el-form-item>

                    <el-form-item label="完成时间:" prop="PlanComplateTime">
                      <el-tooltip :content="'计划完成时间：' + formatPlanComplateTime()" placement="right" effect="light">
                        <!-- <el-input style="width: 75%" :value="formatPlanComplateTime()" :readonly="true"></el-input> -->
                        <span v-text="formatPlanComplateTime()"></span>
                      </el-tooltip>
                    </el-form-item>

                    <el-form-item label="完成后需审核:" label-width="110px">
                      <el-tag :type="checkForm.IsNeedApproval ? 'warning' : 'success'">{{ checkForm.IsNeedApproval ? '是' : '否' }}</el-tag>
                    </el-form-item>

                    <el-form-item label="任务进度:">
                      <el-progress :percentage="pValue1" :stroke-width="12" :status="pValue1 == 100 ? 'success' : null" style="margin-top: 10px"></el-progress>
                    </el-form-item>

                    <el-form-item label="指标配置项:" label-width="100px" v-if="checkForm.TemplateId">
                      <div style="display:inline" v-text="checkForm.TemplateName"></div>
                      <!-- <el-input style="width:85%;" v-model="checkForm.TemplateName" :readonly="true"></el-input> -->
                      <el-popover
                        placement="bottom"
                        width="600"
                        trigger="click">
                        <el-table :data="lookdetail">
                          <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
                          <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
                          <el-table-column property="FieldType" align="center" label="值类型">
                            <template scope="scope">
                              <span>{{formatType(scope.row.FieldType)}}</span>
                            </template>
                          </el-table-column>
                          <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
                          <el-table-column property="FieldMustInput" align="center" label="是否必填">
                            <template scope="scope">
                              <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                        <el-button style="margin-left:5px;" slot="reference" @click="handleLookTemplateDetail(checkForm.TemplateId)" type="text">预览配置项</el-button>
                      </el-popover>
                    </el-form-item>

                    <el-form-item v-if="checkForm.MeetingName && checkForm.TaskClassify == 3" label="例会来源:" prop="MeetingName">
                      <!-- <el-input v-model="checkForm.MeetingName" :readonly="true"></el-input> -->
                      <div v-text="checkForm.MeetingName"></div>
                    </el-form-item>

                    <el-form-item v-if="checkForm.MeetingName && checkForm.TaskClassify == 5" label="会议名称:" prop="MeetingName">
                      <!-- <el-input v-model="checkForm.MeetingName" :readonly="true"></el-input> -->
                      <div v-text="checkForm.MeetingName"></div>
                    </el-form-item>

                    <el-form-item v-if="checkForm.InstructUserName" label="指令人:" prop="InstructUserName">
                      <!-- <el-input v-model="checkForm.InstructUserName" :readonly="true"></el-input> -->
                      <div v-text="checkForm.InstructUserName"></div>
                    </el-form-item>

                    <el-form-item v-if="checkForm.ApprovalName" label="审核人:" prop="ApprovalName">
                      <!-- <el-input v-model="checkForm.ApprovalName" :readonly="true"></el-input> -->
                      <div v-text="checkForm.ApprovalName"></div>
                    </el-form-item>

                    <el-form-item v-show="WorkItemType" label="工作类型:" prop="WorkItemType">
                      <!-- <el-input v-model="WorkItemType" :readonly="true"></el-input> -->
                      <div v-text="WorkItemType"></div>
                    </el-form-item>

                    <el-form-item v-show="checkForm.ExamBasis" label="考核依据:" prop="ExamBasis">
                      <!-- <el-input v-model="checkForm.ExamBasis" :readonly="true" type="textarea" :autosize="true"></el-input> -->
                      <div v-text="checkForm.ExamBasis"></div>
                    </el-form-item>

                    <!-- <el-divider content-position="left">任务相关信息</el-divider> -->

                    <el-form-item v-show="checkForm.ScoreCalcMode" label="核算方式:" prop="ScoreCalcMode">
                      <!-- <el-input v-model="checkForm.ScoreCalcMode" :readonly="true" type="textarea" :autosize="true"></el-input> -->
                      <div v-text="checkForm.ScoreCalcMode"></div>
                    </el-form-item>

                    <el-form-item v-if="checkForm.Rate" label="权重:" prop="Rate">
                      <!-- <el-input v-model="checkForm.Rate" type="number" :readonly="true"></el-input> -->
                      <div v-text="checkForm.Rate"></div>
                    </el-form-item>

                    <el-form-item v-if="checkForm.ReplyTips" label="任务维护格式:" prop="ReplyTips">
                      <!-- <el-input v-model="checkForm.ReplyTips" type="textarea" :autosize="true" :readonly="true"></el-input> -->
                      <div v-text="checkForm.ReplyTips"></div>
                    </el-form-item>

                    <el-form-item v-if="checkForm.Remark" label="备注:" prop="Remark">
                      <!-- <el-input v-model="checkForm.Remark" type="textarea" :autosize="true" :readonly="true"></el-input> -->
                      <div v-text="checkForm.Remark"></div>
                    </el-form-item>

                    <el-form-item label="任务附件:">
                      <ul>
                        <li v-for="(item, index) in checkForm.Files" :key="index" style="line-height: 20px">
                          <a target="_blank" :href="item.FileAddress">{{ item.FileName }}</a>
                        </li>
                      </ul>
                      <span v-if="checkForm.Files.length == 0">无附件</span>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-collapse-item>
            </el-collapse>
            <el-col :span="24">
              <el-card style="margin-left: 10px" shadow="never">
                <div slot="header">
                  <span style="cursor:default;font-size: 20px; font-weight: 500;margin-left:10px;">工作进度维护</span>
                  <el-popover
                    placement="left"
                    trigger="hover">
                    <div style="text-align:center" >
                      <el-progress :percentage="pValue1" :stroke-width="10" type="circle" :width="85" :status="pValue1 == 100 ? 'success' : null" :show-text="true"></el-progress>
                    </div>                    
                    <i slot="reference" class="el-icon-pie-chart" style="font-size:18px;margin-left:1px"></i>
                  </el-popover>
                  <span style="position: relative; top: -3px">
                    <el-button v-show="istaskpro && pValue1<100" type="primary" style="margin-left: 10px; padding-top: -13px" size="mini" @click="addpro" icon="el-icon-plus" round>新增</el-button>
                    <!-- <el-tooltip content="点击新增工作进度" placement="top" effect="light">
                    </el-tooltip> -->
                  </span>
                  <span class="radio" style="float: right; margin-top: 5px">
                    <el-radio-group v-model="reverse" @change="handleChangeReverse()">
                      <el-switch style="margin-top: -7px; margin-right: 10px" @change="fullScreen1()" v-model="value1" inactive-text="全屏"> </el-switch>
                      <el-radio :label="true">倒序</el-radio>
                      <el-radio :label="false">正序</el-radio>
                    </el-radio-group>
                  </span>
                </div>
                <div style="padding-left: 2px">
                  <el-empty v-if="taskprogress.length == 0" description="工作有新进度记得更新哦~"></el-empty>

                  <el-timeline :reverse="reverse">
                    <el-timeline-item v-for="(p, index) in taskprogress" :key="p.Id" color="#2d8cf0" :timestamp="p.ProgressValue + '% ' + p.CreatedOn" placement="top">
                      <el-card class="box-card" shadow="hover" @click.native="handleProcessSelectChange(p.Id, index)">
                        
                        <vxe-table v-show="p.OutputData.length>0 && p.MultOutputData.length==0"
                          show-overflow
                          align="center"
                          max-height="400"
                          :data="p.OutputData"
                          :auto-resize="true"
                          >
                          <vxe-column type="seq" width="50" title="序号"></vxe-column>
                          <vxe-column field="FieldName" title="指标项" ></vxe-column>
                          <vxe-column field="Value" title="值"></vxe-column>
                          <vxe-column field="FieldUnit" title="值单位" ></vxe-column>
                          <!-- <vxe-column field="FieldType" title="值类型" >
                            <template #default="{ row }">
                              <span>{{ formatType(row.FieldType) }}</span>
                            </template>
                          </vxe-column> -->
                        </vxe-table>

                        <vxe-table v-show="p.MultOutputData.length!=0"
                          show-overflow
                          align="center"
                          max-height="400"
                          :data="p.MultOutputData"
                          :auto-resize="true"
                          >
                          <vxe-column type="seq" width="50" title="序号"></vxe-column>
                          <vxe-column :field="item.FieldName" :title="item.FieldName" v-for="item in zhibiaoColumnsTitle" v-bind:key="item.Id" ></vxe-column>
                        </vxe-table>
                        <div v-html="p.ProgressDescription" style="margin-bottom: 20px" class="text item role-item">
                          
                        </div>
                        <div>
                          <el-dropdown @command="showTaskFile" v-show="0 < p.Files.length">
                            <span class="el-dropdown-link"> <i class="el-icon-paperclip"></i> 附件({{ p.Files.length - 1 }})<i class="el-icon--right"></i> </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item class="eldropdownitem" v-bind:key="f.FileName" v-for="f in p.Files" :command="f.FileAddress">{{ f.FileName }}</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>

                        <div style="margin-top: 10px" v-show="p.ReviewCount > 0">
                          <el-collapse @change="handleChangeReview">
                            <el-collapse-item :name="p.Id">
                              <template slot="title">
                                <span style="color: orange"><i class="el-icon-chat-dot-square"></i> 评论({{ p.ReviewCount }})</span>
                              </template>
                              <div :style="{ marginLeft: a.ReplayID?'34px':'10px',backgroundColor: a.ReplayID?'#f5f7fa':'white' }"  style="margin-left: 10px; margin-right: 10px; background-color: #f5f7fa; border-radius: 0px;" v-bind:key="a.Id" v-for="a in mapReview.get(p.Id)">
                                <div style="color: #999">
                                  <span style="margin-left: 5px"><i class="el-icon-user-solid"></i>{{ a.CreatedBy }} <el-tag v-show=" a.ReplayID==null" type="info" size="mini" effect="plain">楼主</el-tag></span><span v-show="a.ReplayID"><span style="color: red">[回复]</span><span style="margin-left: 5px"><i class="el-icon-user-solid"></i>{{ getReplyReview(mapReview.get(p.Id), a.ReplayID).CreatedBy }}</span></span>
                                  <span style=" margin-left : 5px">于{{ a.CreateTime }}</span>
                                </div>
                                <div style="margin-left: 20px; margin-right: 5px; border-radius: 5px;" v-html="a.ReviewContent"></div>
                                <div style=" margin-right: 5px">
                                  <el-popover @show="handlePopoReplyShow" placement="top" :offset="20" height="300" trigger="manual" v-model="a.reply">
                                    <editor style="width: 300px" :catchData="catchData" />

                                    <el-button style="float: right; margin-top: 5px" type="primary" size="mini" @click="handleReplyReview(p, a.Id)">回复</el-button>
                                    <el-button style="float: right; margin-top: 5px; margin-right: 5px" type="normal" size="mini" @click="handleCloseReply(a)">取消</el-button>

                                    <el-button size="mini" style="margin-left:21px;" slot="reference" @click="handleOpenReply(a)" type="text" icon="el-icon-chat-dot-round">回复</el-button>
                                  </el-popover>
                                </div>
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </div>
                        <div>
                          <div style=" width: 100%; padding-right: 5px">
                            <!-- <el-dropdown style="margin-right:55%;">
                            <span class="el-dropdown-link">
                              ···
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item @click="handleDelProgress(p)">删除进度</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown> -->
                            <el-link :title="p.LikeId ? '已点赞' : ''" :type="p.LikeId ? 'primary' : 'default'" :icon="p.LikeId ? 'iconfont icon-dianzan2' : 'iconfont icon-dianzan'" @click="handleLike(p)" style="margin-right: 25px" :underline="false"
                              ><font style="font-size: 9px">{{ p.LikeCount ? '(' + p.LikeCount + ')' : '赞' }}</font></el-link
                            >
                            <el-popover @show="handlePopoShow" placement="bottom" height="300" trigger="manual" v-model="p.sendNew">
                              <editor style="width: 300px" :catchData="catchData" />

                              <div style="position: absolute; bottom: 12px; color: #999; font-size: 7px"><i class="el-icon-user-solid"></i>{{ myname() }}</div>
                              <el-button style="float: right; margin-top: 5px" type="primary" size="mini" @click="handleSendReview(p)">发表</el-button>
                              <el-button style="float: right; margin-top: 5px; margin-right: 5px" type="normal" size="mini" @click="p.sendNew = !p.sendNew">取消</el-button>

                              <el-button style="margin-top: 5px; margin-bottom: 10px" slot="reference" @click="handleOpenSend(p)" size="mini" round type="normal" icon="el-icon-chat-dot-round">说点什么</el-button>
                            </el-popover>
                            <el-tooltip :content="formatDisabledText(index)" placement="left" effect="light">
                              <el-link :disabled="taskprogress.length - 1 != index || !istaskpro || pValue1 == 100" icon="el-icon-delete" style="float:right;margin-top:15px" @click="handleDelProgress(p)" :underline="false"></el-link>
                            </el-tooltip>
                          </div>
                        </div>
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </el-card>
            </el-col>
            <el-backtop target=".yeardiv" :right="40" :bottom="40"> </el-backtop>
          </el-tab-pane>
          <!-- <el-tab-pane label="会议相关" :disabled="!checkForm.MeetingId" name="tabMeeting"> -->
          <el-tab-pane label="更多信息" :disabled="true" name="tabMeeting">
            <el-col :span="12">
              <el-form :model="meetingForm" label-width="85px" ref="meetingForm" style="white-space: nowrap">
                <el-form-item label="会议名称:" prop="MeetingName">
                  <el-input v-model="meetingForm.MeetingName" type="textarea" :autosize="true" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="会议议程:" prop="MeetingDescription">
                  <el-input v-model="meetingForm.MeetingDescription" :readonly="true" type="textarea" :autosize="true"></el-input>
                </el-form-item>
                <el-form-item label="会议发布人:" prop="PublishName">
                  <el-input v-model="meetingForm.PublishName" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="会议日期:" prop="MeetingTime">
                  <el-input :value="formatMeetingTime()" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="会议时间:" prop="StartTime">
                  <el-input :value="meetingForm.StartTime + '至' + meetingForm.EndTime" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="参会人员:" prop="PartInUserName">
                  <el-input v-model="meetingForm.PartInUserName" :readonly="true" type="textarea" :autosize="true"></el-input>
                </el-form-item>
                <el-form-item label="抄送人:" v-if="meetingForm.CCUserName" prop="CCUserName">
                  <el-input v-model="meetingForm.CCUserName" :readonly="true" type="textarea" :autosize="true"></el-input>
                </el-form-item>
                <el-form-item label="备注:" prop="Remark">
                  <el-input v-model="meetingForm.Remark" :readonly="true" type="textarea" :autosize="true"></el-input>
                </el-form-item>
              </el-form>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <!--新增工作进度界面-->
    <el-dialog :visible.sync="addProgressVisible" v-model="addProgressVisible" v-dialogDrag :close-on-press-escape="false" :close-on-click-modal="false" @opened="addProgressOpened" style="width: 90%; left: 11%" center @closed="addProgressClosed">
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px; position: relative; top: -3px">新增工作进度</span>
      </template>
      <el-form :model="addProgressForm" :rules="addProgressFormRule">
        <el-alert v-show="this.checkForm.TaskClassify==4" style='margin-bottom:20px'
          title="你正在维护年度计划任务，请尽可能固定周期（如每月）描述截止当前此年度任务完成情况（成果和量化数据等总结性内容）"
          type="success"></el-alert>
        <el-alert v-show="this.checkForm.TaskClassify!=4"
          title="温馨提示：维护进度内容时请尽可能清晰的描述任务推进过程、量化数据或成果" type="info"></el-alert>
          <br/>
        <div v-if="this.checkForm.TemplateId" >
          <el-button v-if="!showZhibiaoTable && !showZhibiaoTableHorizontal" type="default" icon="el-icon-right" @click.native="handleInsertZhibiao" size="mini">插入指标组件（填写一项）</el-button>
          <el-button v-if="showZhibiaoTable" type="default" icon="el-icon-circle-close" @click.native="handleRemoveZhibiao" size="mini">移除组件</el-button>
          <el-button v-if="!showZhibiaoTableHorizontal && !showZhibiaoTable" type="default" icon="el-icon-right" @click.native="handleInsertZhibiaoHorizontal" size="mini">插入指标组件（填写多项）</el-button>
          <el-button v-if="showZhibiaoTableHorizontal" type="default" icon="el-icon-circle-close" @click.native="handleRemoveZhibiaoHorizontal" size="mini">移除组件</el-button>
        </div>
        

        <el-form-item v-show="showZhibiaoTable">
            <vxe-table
              border
              show-overflow
              keep-source
              ref="xTable"
              align="center"
              max-height="400"
              :scroll-y="{enabled: false}"
              :column-config="{resizable: true}"
              :data="zhibiaoValue"
              :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil', showStatus: true}">
              <vxe-column type="seq" width="50" title="序号"></vxe-column>
              <vxe-column field="FieldName" title="指标项" min-width="100px" ></vxe-column>
              <vxe-column field="Value" title="值" width="100" :edit-render="{autofocus: '.vxe-input--inner' }">
                <template #edit="{ row }">
                  <vxe-input v-model="row.Value" :type="formatTextType(row.FieldType)" :maxlength="row.FieldLength"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column field="FieldType" title="值类型" >
                <template #default="{ row }">
                  <span>{{ formatType(row.FieldType) }}</span>
                </template>
              </vxe-column>
              <vxe-column field="FieldUnit" title="值单位" ></vxe-column>
              <vxe-column field="FieldMustInput" title="值必填" >
                <template #default="{ row }">
                  <span>{{ formatMustinput(row.FieldMustInput) }}</span>
                </template>
              </vxe-column>
            </vxe-table>
        </el-form-item>

        <el-form-item v-show="showZhibiaoTableHorizontal">
          <vxe-toolbar perfect>
            <template #buttons>
              <vxe-button icon="vxe-icon-square-plus" status="perfect" @click="insertZhibiaoRowEvent">新增行</vxe-button>
              <vxe-button icon="vxe-icon-delete" status="perfect" @click="removeZhibiaoRowEvent">移除行</vxe-button>
              <!-- <vxe-button icon="vxe-icon-undo" status="perfect" @click="revertZhibiaoRowEvent">清空</vxe-button> -->
            </template>
          </vxe-toolbar>
            <vxe-table
              border
              show-overflow
              keep-source
              empty-text="点击新增行填写指标数据"
              ref="xTableHorizontal"
              align="center"
              max-height="400"
              :column-config="{resizable: true}"
              :data="zhibiaoValueHorizontal"
              :edit-config="{trigger: 'click', mode: 'row'}">
              <vxe-column type="checkbox" width="60"></vxe-column>
              <vxe-column type="seq" width="50" title="序号"></vxe-column>

              <vxe-column :field="item.FieldName" :edit-render="{autofocus: '.vxe-input--inner',defaultValue:item.FieldDefault,placeholder:item.FieldMustInput?'必填项':'' }" :title="item.FieldName" v-for="item in zhibiaoColumns" v-bind:key="item.Id">
                <template #edit="{ row,column }">
                  <vxe-input :placeholder="item.FieldUnit?'单位:'+item.FieldUnit:item.FieldName" v-model="row[column.property]" :type="formatTextType(item.FieldType)" :maxlength="item.FieldLength" :autosize="{ minRows: 4, maxRows: 4}"></vxe-input>
                  <!-- <el-input :placeholder="item.FieldUnit?'单位:'+item.FieldUnit:item.FieldName" v-model="row[column.property]" :type="formatTextType(item.FieldType)" :maxlength="item.FieldLength" show-word-limit :autosize="{ minRows: 4, maxRows: 4}"></el-input> -->
                </template>
              </vxe-column>
            </vxe-table>
        </el-form-item>
        <!-- <el-form-item label="进度内容:" prop="ProgressDescription">
          <el-input v-model="addProgressForm.ProgressDescription" maxlength="4000" placeholder="请尽可能的描述任务推进过程、量化数据和成果" 
          :show-word-limit="false"
          :rows="4" type="textarea"></el-input>
          <span style="font-size:9px;float:right">快捷文本:
            <el-tag style="margin-left:3px;cursor:pointer" size="mini" effect="plain" @click="handleQuickText('完成')">“完成”</el-tag>
            <el-tag style="margin-left:3px;cursor:pointer" size="mini" effect="plain" @click="handleQuickText('见附件')">“见附件”</el-tag>
            <el-tag style="margin-left:3px;cursor:pointer" size="mini" v-show="showZhibiaoTable" effect="plain" @click="handleQuickText('见表格')">“见表格”</el-tag>
          </span>
        </el-form-item> -->
        <el-form-item label="进度内容:" prop="ProgressDescription">
          <br/>
          <editorprogress ref="editorProgressElem" style="width: 100%" :catchData="catchDataProgress" />
          <!-- <span style="font-size:9px;"><i class="el-icon-info"></i> 编辑进度内容时可以全屏噢~</span> -->
          <span style="font-size:9px;float:right">快捷文本:
            <el-tag style="margin-left:3px;cursor:pointer" size="mini" effect="plain" @click="handleQuickText('完成')">“完成”</el-tag>
            <el-tag style="margin-left:3px;cursor:pointer" size="mini" effect="plain" @click="handleQuickText('见附件')">“见附件”</el-tag>
            <el-tag style="margin-left:3px;cursor:pointer" size="mini" v-show="showZhibiaoTable || showZhibiaoTableHorizontal" effect="plain" @click="handleQuickText('见表格')">“见表格”</el-tag>
          </span>
        </el-form-item>

        <el-form-item label="任务进度:">
          <el-row>
            <el-col :span="12">
              <el-slider v-model="pValue2" @input="handleProcessInput" :step="this.mytype == 'normal' ? 5 : 1"></el-slider>
            </el-col>
            <el-col :span="2.2">
              <div style="margin-left: 9px">{{ pValue2 }}%</div>
            </el-col>
            <el-col :span="7">
              <div style="margin-left: 3px; font-size: 12px"><i class="el-icon-info"></i>拖动滑块设置进度百分比</div>
            </el-col>
          </el-row>

          <!-- <el-row>
                            <el-col :span="15">
                                <el-progress style="margin-top:10px" :percentage="pValue2" :color="customColor"></el-progress>
                            </el-col>
                            <el-col :span="1.5">
                                <div>
                                    <el-button-group>
                                        <el-button icon="el-icon-plus" @click="increase" v-show="showplus"></el-button>
                                        <el-button icon="el-icon-minus" style="float:right;" v-show="showminus"  @click="decrease"></el-button>
                                    </el-button-group>
                                </div>
                            </el-col>
                        </el-row> -->
        </el-form-item>
        <el-form-item label="附件:">
          <upload-files :files="fileList" :key="key" action="https://api.gradgroup.cn/ftp/ftp/upload" :limit="5" @fun="dealFiles2" @delfun="deleteFiles2" :multiple="false" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="checkSubmit">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import store from '../store'
import util from '../../util/date'
import { Loading } from 'element-ui'
import {
  AddTaskCollect,
  RemoveTaskCollect,
  AddTaskProgressLike,
  DeleteTaskProgressLike,
  AddTaskProgressReview,
  QueryReviewByProgressId221107,
  ApproveTaskByTaskId,
  QueryPageWaitDoTasksByUserCode,
  AddTaskProgress,
  QueryTaskProgressByTaskId,
  DeleteTaskProgress,
  QueryTemplateDetailByTemplateId,
  GetUserPostList,
  AssignTask,
  ProlongPlanComplateTime,
  CancelTask,
  QueryChildTasksByParentId,
  UpdateTaskContent,
  QueryTasksById,
  QueryMeetingById,
  GetYearPlanListByUserCode,
  SetTaskAboutYearPlan
} from '../api/oa'
import editor from '../components/editor.vue'
import editorprogress from '../components/editorprogress.vue'
import UploadFiles from '../components/UploadFiles'
import dialogDrag from '../../util/dialogDrag'
export default {
  name: 'taskinfo',
  components: { editor, editorprogress, UploadFiles, util, dialogDrag },
  data() {
    return {
      progressdata:[],
      tabcols:[],
      numcols:[],
      topNub: -110,
      fullScreen: false,
      value1: false,
      dwidth:120,
      dleft:-5,
      reverse: true,
      activeNames: ['1'],
      zhibiaoactiveNames: ['1'],
      popo1: false,
      right: 40,
      CommonTaskClassifyText: '',
      loading: '',
      taskid: '',
      currentYearplanActiveId: null,
      currentActiveId: null,
      // editor: null,
      myheadText: '查看任务',
      mytype: 'normal',
      checkForm: {
        Id: null,
        WorkItem:'',
        WorkItemType: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null,
        Files: []
      },
      meetingForm: {
        Id: null
      },
      addProgressForm: {
        //进度新增表单初始化
        ProgressDescription: '',
        CreatedBy: null,
        CreatedId: null
      },
      addProgressFormRule: {
        ProgressDescription: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      WorkItemType: null,
      activeName: 'tabTask',
      pValue1: 0, //进度条数值
      pValue2: 0,
      taskprogress: [],
      istaskpro: true,
      dialogShow: false,
      likeing: false,
      addProgressVisible: false,
      progresscheck: false,
      operation1: true,
      fileList: [],
      key: 0,
      yearplans: [],
      lookdetail: [],
      zhibiaoColumns:[],
      zhibiaoColumnsTitle:[],
      zhibiaoValue:[],
      zhibiaoValueHorizontal:[],
      typeList: [
        { label: '数字', value: 1 },
        { label: '文本', value: 2 },
        // { label: '日期', value: 3 },
        // { label: '时间', value: 4 }
      ],
      mustinputList:[
        { label: '必填', value: true },
        { label: '非必填', value: false },
      ],
      showZhibiaoTable:false,
      showZhibiaoTableHorizontal:false,
      mapReview: new Map()
    }
  },
  props: ['headerText', 'data', 'type'], // 接收父组件的方法
  watch: {
    content() {
      // this.editor.txt.html(this.content)
    },
    contentProgress(){

    }
  },
  methods: {
    async insertZhibiaoRowEvent(){
      var row =-1;
      const $table = this.$refs.xTableHorizontal
      const record = {
        
      }
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    async removeZhibiaoRowEvent(){
      const $table = this.$refs.xTableHorizontal
      const selectRecords = $table.getCheckboxRecords()
      if (selectRecords.length) {
        
      this.$confirm('您确定要删除选中的数据吗', '提示', {})
        .then(() => {
          $table.removeCheckboxRow()
        })
      } else {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        });
      }
    },
    async revertZhibiaoRowEvent () {
      const type = await VXETable.modal.confirm('您确定要还原数据吗?')
      const $table = this.$refs.xTable
      if (type === 'confirm') {
        $table.revertData()
      }
    },
    sumNum (list, field) {
      let count = 0
      list.forEach(item => {
        if(item[field]!=null && item[field]!=''){
          var num = item[field].replace(/[^\d]/g, " ");
          count += Number(num)
        }
      })
      return count
    },
    footerMethod ({ columns, data }) {
      let self = this;
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if(self.numcols.includes(column.title)){
            var n = self.sumNum(data, column.property)
            if(n>0){return n}else{return ''};
          }else{
            return '';
          }
        })
      ]
    },
    handleQuickText(str)
    {
      this.$refs.editorProgressElem.append(str);
    },
    formatTextType(t)
    {
      if(t==1)
      {
        return 'integer';
      }else if(t==2)
      {
        return 'text'
      }
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    formatMustinput (value) {
      for(var a=0;a<this.mustinputList.length;a++)
      {
        if(this.mustinputList[a].value==value)
        {
          return this.mustinputList[a].label;
        }
      }
      return ''
    },
    async handleInsertZhibiao()
    {
      if(this.lookdetail.length==0){
        let para = { templateId: this.checkForm.TemplateId };
        await QueryTemplateDetailByTemplateId(para).then((res) => {
          // console.log(res)
          this.lookdetail = res.data.response;
        });
      }
      this.zhibiaoValue=[];
      this.lookdetail.forEach(element => {
        this.zhibiaoValue.push(element);
      });
      this.showZhibiaoTable = true;
    },
    async handleInsertZhibiaoHorizontal()
    {
      let para = { templateId: this.checkForm.TemplateId };
      await QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res)
        this.zhibiaoColumns = res.data.response;
        // console.log(res.data.response);
      });
      this.showZhibiaoTableHorizontal = true;
    },
    handleRemoveZhibiao()
    {
      this.zhibiaoValue=[];
      this.showZhibiaoTable = false;
    },
    handleRemoveZhibiaoHorizontal()
    {
      this.zhibiaoValue=[];
      this.showZhibiaoTableHorizontal = false;
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    handleLookTemplateDetail(templateId)
    {
      if(this.lookdetail.length>0){
        return;
      }
      let para = { templateId: templateId };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res)
        this.lookdetail = res.data.response;
      });
    },
    handleChangeReverse()
    {
      var storage = window.localStorage;
      storage.setItem('taskreverse',this.reverse);
    },
    fullScreen1() {
      this.fullScreen=!this.fullScreen;
      
      var storage = window.localStorage;
      storage.setItem('taskfullScreen',this.fullScreen);
      if(this.fullScreen)
      {
        this.topNub=0;
        this.value1 = true
        this.dwidth = 100
        this.dleft = 0
      }else{
        this.topNub=-110;
        this.value1 = false
           this.dwidth = 120
        this.dleft = -5
      }
    },
    handleChange(val) {
      console.log(val)
    },
    formatPlanComplateTime() {
      return !this.checkForm.PlanComplateTime || this.checkForm.PlanComplateTime == '' ? '' : util.formatDate.format(new Date(this.checkForm.PlanComplateTime), 'yyyy-MM-dd')
    },
    formatMeetingTime() {
      return !this.meetingForm.MeetingTime || this.meetingForm.MeetingTime == '' ? '' : util.formatDate.format(new Date(this.meetingForm.MeetingTime), 'yyyy-MM-dd')
    },
    handleOpenSend(p) {
      if (this.mapReview.has(p.Id)) {
        this.mapReview.get(p.Id).forEach((element) => {
          element.reply = false
        })
      }
      this.taskprogress.forEach((element) => {
        if (element.Id != p.Id) {
          element.sendNew = false
        }
      })
      p.sendNew = !p.sendNew
    },
    handleCloseReply(a) {
      a.reply = false
      this.$forceUpdate()
    },
    handleOpenReply(a) {
      this.taskprogress.forEach((element) => {
        element.sendNew = false
      })
      this.mapReview.get(a.ProgressID).forEach((element) => {
        if (element.Id != a.Id) {
          element.reply = false
        }
      })
      a.reply = !a.reply
      this.$forceUpdate()
    },
    formatDisabledText(i) {
      if (!this.istaskpro) {
        return '您无需操作他人的任务进度'
      } else if (this.checkForm.Status == 2) {
        return '已完成的任务无需操作进度'
      } else if (this.taskprogress.length - 1 != i) {
        return '非最新维护的任务进度不允许删除'
      } else {
        return '点击删除任务进度'
      }
    },
    handleDelProgress(progress) {
      // alert();
      console.log(progress)
      if(this.checkForm.Status!=0)
      {
        this.$message({
          message: '当前任务非进行中状态，无法操作进度',
          type: 'warning'
        })
        return;
      }
      // console.log(progress);
      this.$confirm('确认要删除此进度吗？', '提示', {})
        .then(() => {
          DeleteTaskProgress({ id: progress.Id , taskId:progress.TaskID }).then((res) => {
            if (res.data.success) {
              this.$notify({
                title: '操作成功',
                message: res.data.msg,
                type: 'success'
              })
              // this.currentActiveId = null;
              let para = { taskId: this.checkForm.Id }
              QueryTaskProgressByTaskId(para).then((res) => {
                // this.yearplans = res.data.response;
                var data = res.data.response
                var formatData = new Array()
                for (var a = 0; a < data.length; a++) {
                  var element = data[a]
                  element.Files = []
                  if (element.FileName != null) {
                    var arr = element.FileName.split(',')
                    var arr2 = element.FileAddress.split(',')
                    var files = []
                    for (var d = 0; d < arr.length; d++) {
                      var f = {}
                      f.FileName = arr[d]
                      f.FileAddress = arr2[d]
                      files.push(f)
                    }
                    element.Files = files
                  }
                  formatData.push(element)
                }
                this.taskprogress = formatData
                // console.log(this.taskprogress)
                this.RefreshProgress(this.taskprogress)
                this.$nextTick(function () {
                  var div = document.getElementsByClassName('yeardiv')
                  if (div && 0 < div.length) {
                    div[0].scrollTop = div[0].scrollHeight
                  }
                  this.GenerateProgressDataTable();
                })
              })
              // this.getYearPlans();
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.msg,
                type: 'warning'
              })
            }
          })
        })
        .catch(() => {})
    },
    handleLike(progress) {
      // console.log(progress);
      let self = this
      var progressId = progress.Id
      if (progress.LikeId) {
        this.$confirm('您确定要将点赞取消吗', '提示', {})
          .then(() => {
            DeleteTaskProgressLike({ likeId: progress.LikeId, taskId:progress.TaskID }).then((res) => {
              // console.log(res);
              if (res.data.success) {
                this.$notify({
                  title: '操作成功',
                  message: res.data.msg,
                  type: 'success'
                })
                for (var a = 0; a < self.taskprogress.length; a++) {
                  if (this.taskprogress[a].Id == progressId) {
                    console.log(this.taskprogress[a])
                    this.taskprogress[a].LikeCount = this.taskprogress[a].LikeCount - 1
                    this.taskprogress[a].LikeId = null
                    break
                  }
                }
                // this.loadReview(progressId);
              } else {
                this.$notify({
                  title: '网络繁忙',
                  message: res.data.msg,
                  type: 'warning'
                })
              }
            })
          })
          .catch(() => {})
      } else {
        if (this.likeing) {
          return
        }
        var user = JSON.parse(window.localStorage.user)
        var para = {}
        para.TaskID = this.checkForm.Id
        para.ProgressID = progressId
        para.CreatedId = user.sub
        para.CreatedBy = user.name
        // console.log(para);
        this.likeing = true
        AddTaskProgressLike(para).then((res) => {
          // console.log(res);
          this.likeing = false
          if (res.data.success) {
            this.$notify({
              title: '操作成功',
              message: res.data.msg,
              type: 'success',
              duration:2000
            })
            var resultId = res.data.response
            for (var a = 0; a < self.taskprogress.length; a++) {
              if (this.taskprogress[a].Id == progressId) {
                this.taskprogress[a].LikeCount = this.taskprogress[a].LikeCount + 1
                this.taskprogress[a].LikeId = resultId
                break
              }
            }
            // this.loadReview(progressId);
          } else {
            this.likeing = false
            this.$notify({
              title: '网络繁忙',
              message: res.data.msg,
              type: 'warning'
            })
          }
        })
      }
    },
    catchData(c) {
      // console.log(c)
      this.content = c
    },
    catchDataProgress(c){
      this.contentProgress = c
    },
    handlePopoShow() {},
    handlePopoReplyShow() {},
    myname() {
      var user = JSON.parse(window.localStorage.user)
      return user.name
    },
    handleChangeReview(val) {
      // console.log(val)
      if (val.length > 0) {
        var progressId = val[0]
        this.loadReview(progressId)
      }
    },
    handleReplyReview(progress, rid) {
      // console.log(progress);
      // console.log(rid);
      let self = this
      var progressId = progress.Id
      var user = JSON.parse(window.localStorage.user)
      var para = {}
      para.TaskID = this.checkForm.Id
      para.ProgressID = progressId
      para.ReplayID = rid
      para.ReviewContent = self.content
      para.CreatedId = user.sub
      para.CreatedBy = user.name
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$notify({
            title: '操作成功',
            message: res.data.msg,
            type: 'success'
          })
          for (var a = 0; a < self.taskprogress.length; a++) {
            if (this.taskprogress[a].Id == progressId) {
              this.taskprogress[a].ReviewCount = this.taskprogress[a].ReviewCount + 1
              break
            }
          }
          var reviewList = this.mapReview.get(progressId)
          for (var a = 0; a < reviewList.length; a++) {
            if (reviewList[a].Id == rid) {
              reviewList[a].reply = false
              break
            }
          }
          this.$forceUpdate()
          this.loadReview(progressId)
        } else {
          this.$notify({
            title: '网络繁忙',
            message: res.data.msg,
            type: 'warning'
          })
        }
      })
    },
    handleSendReview(progress) {
      let self = this
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() =>
      {
        var progressId = progress.Id
        var user = JSON.parse(window.localStorage.user)
        var para = {}
        para.TaskID = this.checkForm.Id
        para.ProgressID = progressId
        para.ReplayID = null
        para.ReviewContent = self.content
        para.CreatedId = user.sub
        para.CreatedBy = user.name
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$notify({
              title: '操作成功',
              message: res.data.msg,
              type: 'success'
            })
            for (var a = 0; a < self.taskprogress.length; a++) {
              if (this.taskprogress[a].Id == progressId) {
                this.taskprogress[a].ReviewCount = this.taskprogress[a].ReviewCount + 1
                break
              }
            }
            progress.sendNew = false
            this.loadReview(progressId)
          } else {
            this.$notify({
              title: '网络繁忙',
              message: res.data.msg,
              type: 'warning'
            })
          }
        })
      }
      // )
    },
    loadReview(progressid) {
      let self = this
      // console.log(progressid)
      let para = { progressId: progressid }
      QueryReviewByProgressId221107(para).then((res) => {
        // console.log(res)
        var data = res.data.response
        // console.log(data);
        self.mapReview.set(progressid, data)
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      })
    },
    getReplyReview(arr, id) {
      // console.log(arr);
      // console.log(id);
      var o = {
        CreatedBy: '',
        CreatedId: '',
        CreatedTime: '',
        ReviewContent: ''
      }
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].Id == id) {
          o = arr[i]
          break
        }
      }
      return o
    },
    showTaskFile(file) {
      window.open(file, '_blank')
    },
    handleProcessInput(val) {
      if (this.pValue2 < this.pValue1) {
        this.pValue2 = this.pValue1
      }
      if (this.checkForm.ChildrenProgressComplated) {
        if (this.pValue2 >= 100) {
          if (this.WorkItemType == '日常') {
            var t = this.checkForm.PlanComplateTime
            var m = new Date(t).getMonth()
            var m2 = new Date().getMonth()
            if (m2 < m) {
              this.$message({
                message: '日常类年度计划任务不建议在计划完成时间前标记完成',
                type: 'info'
              })
            }
          }

          if (this.checkForm.IsNeedApproval == false) {
            if (this.mytype == 'yearplan') {
              this.$message({
                // message: '任务已完成，请提交！',
                message: '年度计划任务为长周期目标任务，请合理推进任务进度！',
                type: 'info'
              })
            } else {
              this.$message({
                message: '任务已完成，请提交！',
                type: 'success'
              })
            }
          } else {
            this.$message({
              message: '任务已完成，请提交审核！',
              type: 'warning'
            })
          }
          this.pValue2 = 100
        }
      } else {
        // if (this.checkForm.TaskClassify != 4) {
        //   if (this.pValue2 > 95) {
        //     this.$message({
        //       message: '当前任务的子任务未完成,此进度最大只能维护至95%！',
        //       type: 'warning',
        //       duration: 10000
        //     })
        //     this.pValue2 = 95
        //   }
        // }
      }
    },
    deleteFiles2(data) {
      if (data.backData.length >= 0) {
        this.addProgressForm.FileArry = ''
        var filename = ''
        var fileaddress = ''
        data.backData.forEach((element) => {
          filename += element.FileName
          filename += ','
          fileaddress += element.URL
          fileaddress += ','
        })
        this.addProgressForm.FileName = filename
        this.addProgressForm.FileAddress = fileaddress
      }
    },
    dealFiles2(data) {
      if (data.backData.length > 0) {
        this.addProgressForm.FileArry = data.backData
        var filename = ''
        var fileaddress = ''
        data.backData.forEach((element) => {
          filename += element.FileName
          filename += ','
          fileaddress += element.URL
          fileaddress += ','
        })
        this.addProgressForm.FileName = filename
        this.addProgressForm.FileAddress = fileaddress
      }
    },
    addProgressOpened() {
      
    },
    addProgressClosed() {
      this.showZhibiaoTable=false
    },
    addpro() {
      this.addProgressVisible = true
      this.addProgressForm.ProgressDescription = null
      this.showminus = false
      this.showplus = true
    },
    //进度新增提交
    checkSubmit() {
      this.addProgressForm.ProgressDescription = this.contentProgress;
      if(this.showZhibiaoTableHorizontal){
        const $table = this.$refs.xTableHorizontal
        var tableData = $table.getTableData().tableData;
        if(tableData.length>0){
          var firstData = tableData[0];
          var keys = Object.keys(firstData);
          var keysData = keys.slice(0,-1);
          var arr = [];
          var n = 0;
          tableData.forEach(data => {
            // console.log(data);
            let newZhibiaoColumns = Object.assign([],this.zhibiaoColumns)// 返回新对象
            newZhibiaoColumns.forEach(field => {
              var i = keysData.indexOf(field.FieldName);
              var v = data[keysData[i]]
              // console.log(v)
              var newField = Object.assign({},field)
              newField.Value = v;
              var rowNumber = parseInt(n/this.zhibiaoColumns.length);
              newField.RowNumber = rowNumber;
              n++;
              arr.push(newField)
            });
          });
          this.zhibiaoValue = arr;
        }
      }
      // return;
      if(this.showZhibiaoTable || this.showZhibiaoTableHorizontal)
      {
        for(var a =0;a<this.zhibiaoValue.length;a++)
        {
          if(this.zhibiaoValue[a].FieldMustInput)
          {
            var err = false;
            if(this.zhibiaoValue[a].Value==undefined)
            {
              err = true;
            }
            else if(this.zhibiaoValue[a].Value.trim()=='')
            {
              err = true;
            }
            if(err)
            {
              this.$message({
                message: '请完整填写指标数据',
                type: 'warning'
              })
              return;
            }
          }
        }
      }

      var user = JSON.parse(window.localStorage.user)
      let para = {
        taskID: this.taskid,
        progressDescription: this.addProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        filename: this.addProgressForm.FileName,
        fileaddress: this.addProgressForm.FileAddress,
        createdId: user.sub,
        createdBy: user.name
      }
      if((this.showZhibiaoTable || this.showZhibiaoTableHorizontal) && this.zhibiaoValue.length>0)
      {
        var arr = new Array();
        var n = 0;
        this.zhibiaoValue.forEach(element => {
          arr.push({
            templateId:this.checkForm.TemplateId,
            detailId:element.Id,
            value:element.Value,
            rowNumber:element.RowNumber==undefined?n:element.RowNumber
          })
          n++;
        });
        para.inputData = arr;
      }
      // console.log(para);
      // return;
      if (this.addProgressForm.ProgressDescription == null) {
        this.$message({
          message: '请填写进度内容',
          type: 'warning'
        })
      }
      else if(this.addProgressForm.ProgressDescription.length==0){
        this.$message({
          message: '请填写进度内容',
          type: 'warning'
        })
      }
      else {
        if (this.addProgressForm.ProgressDescription.length > 3000) {
          this.$confirm('检测到进度内容填写字数可能过多，或许您可尝试将进度内容以附件方式上传，确定要继续提交吗？', '提示', {})
          .then(() => {
            this.DoAddTaskProgress(para);
          })
          .catch(() => {})
        }else{
          this.DoAddTaskProgress(para);
        }
      }
    },
    DoAddTaskProgress(para)
    {
      this.Loadingstart()
      AddTaskProgress(para)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: '操作成功',
              message: res.data.msg,
              type: 'success',
              duration:2400
            })
            this.Loadignend()
            let para = { taskId: this.taskid }
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.tasks = res.data.response;
              var data = res.data.response
              let formatData = new Array()
              for (var a = 0; a < data.length; a++) {
                var element = data[a]
                element.Files = []
                if (element.FileName != null) {
                  var arr = element.FileName.split(',')
                  var arr2 = element.FileAddress.split(',')
                  var files = []
                  for (var d = 0; d < arr.length; d++) {
                    var f = {}
                    f.FileName = arr[d]
                    f.FileAddress = arr2[d]
                    files.push(f)
                  }
                  element.Files = files
                }
                formatData.push(element)
              }
              this.taskprogress = formatData
              this.RefreshProgress(this.taskprogress)
              this.$nextTick(function () {
                var div = document.getElementsByClassName('tasksdiv')
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight
                }
                this.GenerateProgressDataTable();
              })
            })
            this.addProgressVisible = false
            this.$refs.editorProgressElem.clean('');
            // this.getApprovals();
          } else {
            this.Loadignend()
            this.$notify({
              title: '网络繁忙',
              message: '网络繁忙，请稍候再次尝试',
              type: 'warning'
            })
          }
        })
        .catch((er) => {
          this.Loadignend()
          this.$message({
            message: '网络繁忙，请稍候再次尝试',
            type: 'error'
          })
        })
    },
    GenerateProgressDataTable()
    {
      // console.log(this.taskprogress);
      this.progressdata = [];
      var data = []
      var arr2 =[];
      this.taskprogress.forEach(element => {
        element.MultOutputData=[]
        if(element.OutputData.length>0)
        {
          element.OutputData.forEach(element => {
            data.push(element)
          });
          // console.log(data)
          var cols = [];
          for(var d =0;d<data.length;d++)
          {
            if(cols.indexOf(data[d].FieldName)==-1){
              cols.push(data[d].FieldName)
            }
          }
          // console.log(cols)
          this.tabcols = cols
          var numRe = new RegExp('^[-\\+]?([0-9]+\\.?)?[0-9]+$')
          for(var n=0;n<data.length;n++){
            if(numRe.test(data[n].Value)){
              if(!this.numcols.includes(data[n].FieldName)){
                this.numcols.push(data[n].FieldName);
              }
            }
          }

          if(this.zhibiaoColumnsTitle.length==0){
            var columns = [];
            element.OutputData.forEach(element => {
              var filters = columns.filter(c=>c.FieldName==element.FieldName);
              if(filters.length==0){
                columns.push(element)
              }
            });
            this.zhibiaoColumnsTitle = columns;
          }
          if(this.zhibiaoColumnsTitle.length!=0){
            if(element.OutputData.length>this.zhibiaoColumnsTitle.length){
              var arr = [];
              var rowNumberArr = [];
              element.OutputData.forEach(element =>{
                var filters = rowNumberArr.filter(d=>d==element.RowNumber);
                if(filters.length==0){
                  rowNumberArr.push(element.RowNumber)
                }
              })
              for(var a=0;a<rowNumberArr.length;a++){
                var rowNumber = rowNumberArr[a];
                var filters = element.OutputData.filter(d=>d.RowNumber==rowNumber);
                var obj = {};
                filters.forEach(element => {
                  var fieldName=element.FieldName;
                  this.$set(obj,fieldName,element.Value)
                })
                obj.date = element.CreatedOn
                arr.push(obj);
                arr2.push(obj);
              }
              element.MultOutputData = arr;
            }else if(element.OutputData.length==this.zhibiaoColumnsTitle.length){
              var filters = element.OutputData;
              var obj = {};
              filters.forEach(element => {
                var fieldName=element.FieldName;
                this.$set(obj,fieldName,element.Value)
              })
              obj.date = element.CreatedOn
              arr2.push(obj);
            }
          }
        }
      });
      this.progressdata = arr2;
    },
    RefreshProgress(tasksProgressRow) {
      var rowlength = tasksProgressRow.length
      if (rowlength > 0) {
        this.pValue2 = tasksProgressRow[tasksProgressRow.length - 1].ProgressValue
      } else {
        this.pValue2 = 0
      }
      this.pValue1 = this.pValue2
      // if (this.pValue1 == 95 && this.progresscheck == false) {
      if (false) {
        this.istaskpro = false
      } else {
        this.istaskpro = true
      }
    },
    checkclose() {
      this.pValue1 = 0
      this.$emit('closeFunction', {})
    },
    Loadingstart() {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend() {
      this.loading.close()
    },
    handleTabClick(val) {
      // console.log(val);
      // if(val.name == "tabMeeting")
      // {
      // }
    },
    loadMeeting() {
      QueryMeetingById({ Id: this.checkForm.MeetingId }).then((res) => {
        // console.log(res);
        Object.assign(this.meetingForm, res.data.response)
      })
    },
    handleFormatTime(t) {
      return t
    },
    handleProcessSelectChange(taskid, index) {
      this.currentActiveId = taskid
      this.currentProgressRowIndex = index
    }
  },
  mounted() {
    var storage = window.localStorage;
    var taskfullScreenVal = storage.getItem("taskfullScreen");
     console.log(taskfullScreenVal,'taskfullScreen')
    if(taskfullScreenVal === 'true')
    {
         this.topNub=0;
        this.value1 = true
        this.dwidth = 100
        this.dleft = 0
      this.fullScreen = true
    
    }
    var taskreverseVal = storage.getItem("taskreverse");
    if(taskreverseVal === 'false')
    {
      this.reverse =false;
    }
    
    // console.log(document.body.clientWidth, document.body.clientWidth * 0.6 * 0.5, '  document.body.clientWidth')
    // this.right = (document.body.clientWidth - document.body.clientWidth * 0.6) * 0.6
    if (this.headerText) {
      this.myheadText = this.headerText
    }
    if (this.type) {
      this.mytype = this.type
      console.log(this.mytype)
    }
    if (this.data) {
      var row = this.data
      // console.log(row);
      this.checkForm = Object.assign({ Files: [] }, row)
      // console.log(this.checkForm)
      this.dialogShow = true
      if (row.TaskClassify == 1) {
        this.CommonTaskClassifyText = row.CommonTaskClassifyText
        // this.showorganize = false;
        // this.showyearplan = false;
        // this.showregularproject = false;
        // this.showprofessionmeetong = false;
        // this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = '跨部门协调'
          // this.showorganize = true;
          // this.showyearplan = false;
          // this.showregularproject = false;
          // this.showprofessionmeetong = false;
          // this.createPerson = false;
        } else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = '例会项目'
          // this.showorganize = false;
          // this.showyearplan = false;
          // this.showregularproject = true;
          // this.showprofessionmeetong = false;
          // this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = '年度计划'
          // this.showorganize = false;
          // this.showyearplan = true;
          // this.showregularproject = false;
          // this.showprofessionmeetong = false;
          // this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = '专项会议'
          // this.showorganize = false;
          // this.showyearplan = false;
          // this.showregularproject = false;
          // this.showprofessionmeetong = true;
          // this.createPerson = true;
        } else {
          this.CommonTaskClassifyText = '其他'
          // this.showorganize = false;
          // this.showyearplan = false;
          // this.showregularproject = false;
          // this.showprofessionmeetong = false;
          // this.createPerson = false;
        }
      }
      var taskid = ''
      // console.log(row);
      if (row.TaskId) {
        taskid = row.TaskId
      } else {
        taskid = row.Id
      }
      this.taskid = taskid
      QueryTasksById({ Id: taskid, taskClassify: row.TaskClassify }).then((res) => {
        // this.checkForm = res.data.response;
        // console.log(res.data.response);
        this.checkForm = Object.assign(this.checkForm, res.data.response)
        this.checkForm.Files = []
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(',')
          var arr2 = res.data.response.FileAddress.split(',')
          var files = []
          for (var a = 0; a < arr.length; a++) {
            var f = {}
            f.Id = a
            f.FileName = arr[a]
            f.FileAddress = arr2[a]
            files.push(f)
          }
          this.checkForm.Files = files
        }
        this.pValue1 = this.checkForm.TaskProgressValue;
        this.pValue2 = this.checkForm.TaskProgressValue;
      })
      // this.pValue1 = row.TaskProgressValue
      // this.pValue2 = row.TaskProgressValue
      this.progresscheck = this.checkForm.ChildrenProgressComplated
      var ucode = JSON.parse(window.localStorage.user)
      // console.log(row)
      if (ucode.sub != row.PersonOfDutyCode || row.Status == 5 || row.Status == 4) {
        this.istaskpro = false
      }
      //  else {
      //   if (this.pValue1 < 100) {
      //     // if (this.pValue1 == 95 && this.progresscheck == false ) {
      //     // if (this.pValue1 == 95 && this.progresscheck == false ) {
      //       this.istaskpro = false;
      //     } else {
      //       this.istaskpro = true;
      //     }
      //   } else {
      //     this.istaskpro = false;
      //   }
      // }
      let para = { taskId: taskid }
      QueryTaskProgressByTaskId(para).then((res) => {
        var data = res.data.response
        var formatData = new Array()
        for (var a = 0; a < data.length; a++) {
          var element = data[a]
          element.Files = []
          if (element.FileName != null) {
            var arr = element.FileName.split(',')
            var arr2 = element.FileAddress.split(',')
            var files = []
            for (var d = 0; d < arr.length; d++) {
              var f = {}
              f.FileName = arr[d]
              f.FileAddress = arr2[d]
              files.push(f)
            }
            element.Files = files
          }
          formatData.push(element)
        }
        this.taskprogress = formatData
        this.$nextTick(function () {
          var div = document.getElementsByClassName('tasksdiv')
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight
          }
          this.GenerateProgressDataTable();
        })
      })
      if(this.checkForm.CommonTaskClassify==35){
        setTimeout(() => {
          this.activeNames = ['2'];
        }, 200);
      }
      setTimeout(() => {
        if (this.checkForm.MeetingId) {
          this.loadMeeting()
        }
      }, 500)
    }
  }
}
</script>
<style scoped>
.active {
  background-color: #00000014;
}
/deep/ .el-collapse-item__arrow {
  margin: 0;
  transition: transform 0.3s;
  font-weight: 300;
}
/deep/ .el-dialog.is-fullscreen {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden;
}
</style>
