import Vue from 'vue'

// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', {
    bind(el, binding, vnode, oldVnode) {
        const headerElement = el.querySelector('.el-dialog__header')
        const dragDialog = el.querySelector('.el-dialog')
        headerElement.style.cursor = 'move'

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDialog.currentStyle || window.getComputedStyle(dragDialog, null)

        headerElement.onmousedown = (e) => {
            
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - headerElement.offsetLeft
            const disY = e.clientY - headerElement.offsetTop
            console.log(e.clientX, headerElement.offsetLeft)
            // 获取到的值带px 正则匹配替换
            let styL, styT
            console.log(sty.left)
            
            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
                styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
                styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
            } else {
                styL = +sty.left.replace(/\px/g, '')  // 写成/px/g 也行
                styT = +sty.top.replace(/\px/g, '')
            }

            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                const l = e.clientX - disX
                const t = e.clientY - disY
                console.log(disX)
                // 移动当前元素
                dragDialog.style.left = `${l + styL}px`
                dragDialog.style.top = `${t + styT}px`

                // 将此时的位置传出去
                // binding.value({x:e.pageX,y:e.pageY})
            }

            document.onmouseup = function (e) {
                document.onmousemove = null
                document.onmouseup = null
            }
        }
    }
})